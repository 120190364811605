import { DOMContentLoaded } from '~utils';

class Global {
  #module = null;
  constructor(options) {
    this.#wireGlobalScript(options);
  }

  async #wireGlobalScript({ script, selector = document.documentElement }) {
    const elements =
      typeof selector == 'string'
        ? [...document.querySelectorAll(selector)]
        : NodeList.prototype.isPrototypeOf(selector) ||
          Symbol.iterator in Object(selector)
        ? [...selector]
        : Element.prototype.isPrototypeOf(selector)
        ? [selector]
        : false;

    const importGlobalScript = () =>
      script()
        .then(module => (this.#module = module.default))
        .catch(() => Promise.resolve());

    if (elements.length) {
      await importGlobalScript();

      DOMContentLoaded(() => {
        elements.forEach(e => this.#module.apply(e));
      });
    }
  }
}

export { Global, Global as default };
