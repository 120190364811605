import { toggle } from '~plugins';
import { screen, addEvents } from '~utils';

export default function () {
  const containers = [...this.querySelectorAll('.footer__column')];

  containers.forEach(container => {
    const trigger = container.querySelector('.footer__title');
    const content = container.querySelector('.footer__list');

    const accordion = new toggle({
      container,
      trigger,
      content,
      props: ['visibility', 'opacity', 'height'],
      externalEvents: true,
    });

    const adjustTabIndex = () => {
      if (screen.md().matches) {
        trigger.removeAttribute('tabindex');
      } else {
        trigger.setAttribute('tabindex', 0);
      }
    };

    adjustTabIndex();
    screen.md().addEventListener('change', adjustTabIndex);

    addEvents(trigger, 'click.stop.prevent enter.stop.prevent', () => {
      if (!screen.md().matches) {
        accordion.toggle();
      }
    });
  });
}
