import { toggle } from '~plugins';

export default function () {
  const items = [...this.querySelectorAll('.accordion__item')];
  const relations = [];

  items.forEach(container => {
    let trigger = container.querySelector('.accordion__trigger');
    let content = container.querySelector('.accordion__content');
    let config = {
      container,
      trigger,
      content,
      relations,
      stagger: false,
    };
    relations.push(new toggle(config));
  });
}
