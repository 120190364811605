import { toggle } from '~plugins';

export default function () {
  const tools = this;

  // Removed because setting this dynamically per user is causing CLS
  // const view = () => {
  //   const view = window.localStorage.getItem('view');
  //   const container = tools.closest('.listing') || tools;
  //   const toggles = [...tools.querySelectorAll('.list-tools__view-button')];

  //   const changeView = (view) => {
  //     container.setAttribute('data-view', view);
  //     window.localStorage.setItem('view', view)
  //   }

  //   const init = () => {
  //     if (view) changeView(view);
  //     else changeView(toggles[0].dataset.view);
  //     toggles.forEach(t => t.addEventListener('click', () => changeView(t.dataset.view)));
  //   }

  //   init();
  // }
  // view();

  const select = () => {
    const items = [...tools.querySelectorAll('.list-tools__select')];
    const relations = [];

    const closeAll = e => {
      if (e.target.closest('.list-tools__select')) return;
      relations.forEach(r => r.close());
    };

    items.forEach(container => {
      let trigger = container.querySelector('.list-tools__select-active');
      let content = container.querySelector('.list-tools__select-menu');
      let config = {
        container,
        trigger,
        content,
        relations,
      };
      let select = new toggle(config);
      relations.push(select);
    });

    window.addEventListener('click', closeAll);
  };
  select();
}
