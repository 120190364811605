import { spread, regionalEach } from '~utils/polyfills';

const polyfill_adoptedStyleSheets = () => {
  const needs_polyfill =
    document !== 'undefined' && !('adoptedStyleSheets' in document);
  const result = [Promise.resolve()];

  if (needs_polyfill) {
    result.push(
      import(
        /* webpackMode: "lazy-once" */
        `${'construct-style-sheets-polyfill?bp-force-chunk'}`
      )
    );
  }

  return Promise.all(result);
};

const polyfill_custom = () => {
  return Promise.all([spread(), regionalEach()]);
};

export default () =>
  Promise.all([polyfill_adoptedStyleSheets(), polyfill_custom()]);
