import Setup from '~setup';
await Setup();

import Component from '~component';
import Global from '~global';
import '~styles/main';

new Global({
  script: () => import(/* webpackMode: 'eager' */ `./global/js/using-mouse.js`),
  selector: 'body',
});

// // Excluding youtube-player because it impacts client-side performance
// // and is not used by default in StarterKit projects.
// // Add it back if your project requires it. - Mike Wills, 11/23/2022
// new Global({
//   file: 'youtube-player.js',
//   script: () => import(/* webpackMode: 'eager '*/ `./global/js/youtube-player.js`),
// });

new Component({
  name: 'app-alert',
  script: () =>
    import(/* webpackMode: 'eager' */ `./components/app-alert/index.js`),
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-alert/index.scss`),
});

new Component({
  name: 'app-header',
  script: () =>
    import(/* webpackMode: 'eager' */ `./components/app-header/index.js`),
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-header/index.scss`),
});

new Component({
  name: 'app-footer',
  script: () =>
    import(/* webpackMode: 'eager' */ `./components/app-footer/index.js`),
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-footer/index.scss`),
});

new Component({
  name: 'app-accordion',
  script: () =>
    import(/* webpackMode: 'eager' */ `./components/app-accordion/index.js`),
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-accordion/index.scss`),
});

new Component({
  name: 'app-breadcrumbs',
  style: () =>
    import(
      /* webpackMode: 'eager' */ `./components/app-breadcrumbs/index.scss`
    ),
});

new Component({
  name: 'app-feature',
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-feature/index.scss`),
});

new Component({
  name: 'app-list-tools',
  script: () =>
    import(/* webpackMode: 'eager' */ `./components/app-list-tools/index.js`),
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-list-tools/index.scss`),
});

new Component({
  name: 'app-list-item',
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-list-item/index.scss`),
});

new Component({
  name: 'app-pagination',
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-pagination/index.scss`),
});

new Component({
  name: 'app-section-header',
  style: () =>
    import(
      /* webpackMode: 'eager' */ `./components/app-section-header/index.scss`
    ),
});

new Component({
  name: 'app-title',
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-title/index.scss`),
});

new Component({
  name: 'app-wysiwyg',
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/app-wysiwyg/index.scss`),
});

new Component({
  name: 'blue-form',
  script: () =>
    import(/* webpackMode: 'eager' */ `./components/blue-form/index.js`),
  style: () =>
    import(/* webpackMode: 'eager' */ `./components/blue-form/index.scss`),
});

new Component({
  name: 'app-cookie-notice',
  script: () =>
    import(
      /* webpackMode: 'eager' */ `./components/app-cookie-notice/index.js`
    ),
  style: () =>
    import(
      /* webpackMode: 'eager' */ `./components/app-cookie-notice/index.scss`
    ),
});
