/*
This file should only contain the code for the plugin, not any specific instance of it being used. Please put your code for retach in the file for the component/widget you are retaching. Ex. if you need to move the header nav from the normal place to the mobile drawer, put the retach code for that in widgets/_header.js 
USAGE:
this.$retach('.thingToMove', {
  destination: '.placeToPutIt', 
  mediaQuery: 991,
  movedClass: 'is-moved',
  moveMethod: 'append', 
  wrapper: 'quote',
  wrapperClass: 'my-wrapper',
  callback_move() {
    this.style.fontSize = '2rem'
  },
  callback_back() {
    this.style.fontSize = ''
  }
})
*/
import { merge } from 'lodash-es';

import { createElement, unwrap, wrap } from '~utils';

export default function retach(ele, opts) {
  const elements =
    typeof ele == 'string'
      ? [...document.querySelectorAll(ele)]
      : Array.isArray(ele) || NodeList.prototype.isPrototypeOf(ele)
      ? [...ele]
      : [ele];
  const defaults = {
    destination: 'body',
    mediaQuery: 1023,
    movedClass: 'is-moved',
    moveMethod: 'append',
    wrapper: '',
    wrapperClass: '',
    callback_move: function () {
      return;
    },
    callback_back: function () {
      return;
    },
  };
  const options = merge(defaults, opts);
  const dest =
    typeof options.destination === 'string'
      ? document.querySelector(options.destination)
      : options.destination;
  const media =
    typeof options.mediaQuery === 'object'
      ? options.mediaQuery
      : window.matchMedia(`(max-width:${options.mediaQuery}px)`);
  const movedClass = options.movedClass;
  const moveMethod = options.moveMethod;
  const callback_move = options.callback_move;
  const callback_back = options.callback_back;
  const placeholderID =
    Math.floor(Math.random() * 10000 + 1) +
    Math.floor(Math.random() * 10000 + 1);
  const placeholder = createElement({
    tag: 'i',
    attributes: {
      class: 'placeholder',
      'data-placeholderid': placeholderID,
    },
  });
  const wrapperClass = options.wrapperClass;
  let wrapperID;
  let wrapper;

  if (!elements[0]) {
    console.error(
      `The selected elements for retach (${ele}) does not exist on the page.`
    );
    return;
  }

  if (!dest) {
    console.error(
      `The selected retach destination for ${ele} does not exist on the page.`
    );
    return;
  }

  if (options.wrapper) {
    wrapperID =
      Math.floor(Math.random() * 10000 + 1) +
      Math.floor(Math.random() * 10000 + 1);
    wrapper = createElement({
      tag: options.wrapper,
      attributes: {
        'data-wrapperid': wrapperID,
        class: wrapperClass,
      },
    });
  }

  const move = el => {
    let shouldMove = media.matches;
    let hasClass = el.classList.contains(movedClass);

    if (shouldMove && !hasClass) {
      if (moveMethod === 'prepend') {
        dest.insertAdjacentElement('afterbegin', el);
      } else if (moveMethod === 'append') {
        dest.insertAdjacentElement('beforeend', el);
      }

      el.classList.add(movedClass);
      callback_move.call(el);

      if (wrapper) {
        wrap(el, wrapper);
      }
    }
    return;
  };

  const back = el => {
    let shouldMove = media.matches;

    if (!shouldMove) {
      if (
        wrapper &&
        el.parentElement.getAttribute('data-wrapperid') == wrapperID
      ) {
        unwrap(el);
      }
      placeholder.insertAdjacentElement('afterend', el);
      el.classList.remove(movedClass);
      callback_back.call(el);
    }
    return;
  };

  placeholder.style.display = 'none';
  elements[0].insertAdjacentElement('beforebegin', placeholder);

  let original = [...elements];
  let reversed = [...elements].reverse();
  original.forEach(element => {
    move(element);
    media.addListener(() => move(element));
  });
  reversed.forEach(element => {
    back(element);
    media.addListener(() => back(element));
  });
}
