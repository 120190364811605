import { camelCase, addEvents } from '~utils';
import { isPlainObject } from 'lodash-es';

export default class {
  constructor({
    content,
    trigger,
    container,
    relations,
    initialState,
    stagger,
    scrollTest,
    offsetElements,
    externalEvents,
    eventString,
    props,
    media,
    timing,
    on,
  }) {
    this.#content = content;

    trigger ? (this.#trigger = trigger) : false;
    container ? (this.#container = container) : false;
    relations ? (this.#relations = relations) : false;
    stagger !== undefined ? (this.#stagger = stagger) : false;
    media ? (this.#media = media) : false;

    if (initialState !== undefined) {
      this.#active = initialState === 'open';
    }

    isPlainObject(on)
      ? Object.entries(on).forEach(([key, callback]) => {
          const exists = Object.hasOwn(this.#on, key);
          const is_function = typeof callback === 'function';
          if (exists && is_function) this.#on[key] = callback;
        })
      : false;

    scrollTest ? (this.#scrollTest = scrollTest) : false;
    this.#offsetElements = offsetElements
      ? typeof offsetElements === 'string'
        ? [...document.querySelectorAll(offsetElements)]
        : typeof offsetElements[Symbol.iterator] === 'function'
        ? [...offsetElements]
        : [offsetElements]
      : [];

    externalEvents ? (this.#externalEvents = externalEvents) : false;
    !!eventString ? (this.#eventString = eventString) : false;

    timing ? (this.#timing = timing) : false;
    props
      ? ((this.#properties.props = props.map(camelCase)),
        (this.#properties.default = false))
      : false;

    this.#initialize();
    return this;
  }

  #trigger; // required element to click on that toggles the active state
  #content; // required element whose styles are animated

  #container; // optional element that also gets the active flag

  #stagger = true; // if set to true (default) related toggles finish close animation before opening the next, if false, the close and open animations happen simultaneously

  #collection = []; // array of grouped toggle instances accessed through getter and setter for this.#relations
  get #relations() {
    return this.#collection.filter(i => i !== this);
  }
  set #relations(value) {
    this.#collection = value;
  }

  #media; // media query object which, if it exists, only allows toggle to run when it matches

  #determineScroll = () => false; // function (or bool) used to determine if we should scroll to the content on open, accessed through this.#scrollTest getter and setter
  get #scrollTest() {
    return this.#determineScroll();
  }
  set #scrollTest(value) {
    const type = typeof value;
    let test = value;
    let error = false;
    if (type === 'boolean') test = () => value;
    else if (type === 'function') error = test() === undefined;
    else error = true;

    if (error) {
      throw new Error(
        'new Toggle({}) <scrollTest> must be a boolean or a function that returns a boolean'
      );
    }
    this.#determineScroll = test;
  }

  #offsetElements = []; // array of elements whose vertical height should be accounted for if scrollTest = true

  #externalEvents = false; //set to true to handle event triggering externally
  #eventString = 'click.stop.prevent enter.stop.prevent'; // event string to pass to addEvents

  #properties = {
    //properties to toggle (default is height from 0 to auto)
    default: true,
    props: ['height', 'visibility', 'opacity'],
  };
  #timing = {
    //animation timing properties
    duration: 350,
    easing: 'ease',
  };

  #active = false; // active state of the toggle accessed through this.#is_active getter/setter
  #apply_styles = () => {
    if (!this.#properties.default) return;
    this.#content.style.cssText = '';
    const { overflowX, overflowY } = getComputedStyle(this.#content);
    this.#content.style.cssText = `
      overflow-x: ${overflowX === 'visible' ? 'hidden' : overflowX};
      overflow-y: ${overflowY === 'visible' ? 'hidden' : overflowY};
      height: ${this.#active ? 'auto' : 0};
      visibility: ${this.#active ? 'visible' : 'hidden'};
      opacity: ${this.#active ? 1 : 0};
      will-change: height, visibility, opacity;
    `;
  };
  get #is_active() {
    return this.#active;
  }
  set #is_active(value) {
    this.#active = value;
    this.#apply_styles();
  }

  #id = (() =>
    //unique data id applied to the trigger and content for scoping css and for relating labels to content
    `data-${Math.random()
      .toString(36)
      .split('')
      .filter((value, index, self) => self.indexOf(value) === index)
      .join('')
      .substring(2, 8)}`)();

  #get_property_values(c) {
    const styles = getComputedStyle(c);
    return this.#properties.props.reduce((values, prop) => {
      values[prop] = styles[prop];
      return values;
    }, {});
  }
  #get_offset() {
    return this.#offsetElements.reduce(
      (offset, o) => ((offset += o.offsetHeight), offset),
      0
    );
  }
  #animate(el, keyframes) {
    let animation = el.animate(keyframes, this.#timing);
    if (this.#on.toggle !== null) {
      animation.onfinish = () =>
        this.#on.forEach(cb => cb(this.#is_active, this));
    }
    return animation;
  }
  #scroll_to() {
    if (this.#scrollTest) {
      const reference = this.#trigger || this.#content;
      const top = reference.getBoundingClientRect().top - this.#get_offset();
      scrollBy({ top, behavior: 'smooth' });
    }
  }
  #cleanup_container() {
    const c = this.#container;
    if (!c) return;
    c.removeAttribute('toggler', '');
    c.removeAttribute('data-active');
  }
  #cleanup_trigger() {
    const t = this.#trigger;
    if (!t) return;
    t.removeAttribute('toggler');
    t.removeAttribute('id');
    t.removeAttribute('aria-expanded');
    t.removeAttribute('aria-labelledby');
    t.removeAttribute('aria-controls');
    t.removeAttribute('tabindex');
    t.removeAttribute('role');
    t.removeAttribute('data-active');
  }
  #cleanup_content() {
    const c = this.#content;
    c.removeAttribute('toggler');
    c.removeAttribute(this.#id);
    c.removeAttribute('id');
    c.removeAttribute('aria-labelledby');
    c.removeAttribute('role');
    c.removeAttribute('data-active');
    c.removeAttribute('style');
  }
  #process_container() {
    const c = this.#container;
    if (!c) return;
    c.setAttribute('toggler', '');
    if (this.#is_active) c.setAttribute('data-active', '');
  }
  #process_trigger(bind_events = true) {
    const t = this.#trigger;
    if (!t) return;
    const t_id = `${this.#id}-trigger`;
    const c_id = `${this.#id}-content`;

    t.setAttribute('toggler', '');
    t.setAttribute('id', t_id);
    t.setAttribute('aria-expanded', 'false');
    t.setAttribute('aria-labelledby', t_id);
    t.setAttribute('aria-controls', c_id);
    t.setAttribute('tabindex', '0');
    t.setAttribute('role', 'button');
    if (this.#is_active) t.setAttribute('data-active', '');

    if (!bind_events) return;

    if (!this.#externalEvents) {
      if (this.#eventString.includes('hover')) {
        addEvents(t, this.#eventString, {
          in: this.toggle,
          out: this.toggle,
        });
      } else {
        addEvents(t, this.#eventString, this.toggle);
      }
    }
  }
  #process_content() {
    const c = this.#content;
    const t_id = `${this.#id}-trigger`;
    const c_id = `${this.#id}-content`;

    c.setAttribute('toggler', '');
    c.setAttribute(this.#id, '');
    c.setAttribute('id', c_id);
    if (this.#trigger) c.setAttribute('aria-labelledby', t_id);
    if (this.#is_active) c.setAttribute('data-active', '');
    this.#apply_styles();

    /*
     * role=region breaks the default semantics of these elements
     * and the role is inferred for these elements
     */
    const nonRegionNodes = ['UL', 'OL', 'DL', 'LI'];
    if (!nonRegionNodes.includes(c.nodeName)) {
      c.setAttribute('role', 'region');
    }
  }
  #process_media() {
    const m = this.#media;
    if (!m) return;
    const handleMedia = ({ matches }) => {
      if (matches) {
        this.#process_container();
        this.#process_trigger(false);
        this.#process_content();
      } else {
        this.#cleanup_container();
        this.#cleanup_trigger();
        this.#cleanup_content();
      }
    };
    addEvents(m, 'change', handleMedia);
    handleMedia(m);
  }
  #initialize() {
    this.#process_container();
    this.#process_trigger();
    this.#process_content();
    this.#process_media();
  }

  #on = {
    beforeToggle: () => Promise.resolve(),
    afterToggle: () => Promise.resolve(),
    beforeClose: () => Promise.resolve(),
    afterClose: () => Promise.resolve(),
    beforeOpen: () => Promise.resolve(),
    afterOpen: () => Promise.resolve(),
  };

  #do_toggle(state) {
    return new Promise(async resolve => {
      if (this.#media && !this.#media.matches) return resolve();
      if (state === this.#is_active) return resolve();

      const container = this.#container;
      const trigger = this.#trigger;
      const content = this.#content;
      const keyframes = [];

      const close_relations = async () => {
        const animations = Promise.all(this.#relations.map(r => r.close()));
        if (this.#stagger) await animations;
        return Promise.resolve();
      };

      const toggle_self = () =>
        new Promise(resolve => {
          keyframes.push(this.#get_property_values(content));
          container ? container.toggleAttribute('data-active', state) : false;
          trigger ? trigger.toggleAttribute('data-active', state) : false;
          trigger ? trigger.setAttribute('aria-expanded', state) : false;
          content.toggleAttribute('data-active', state);
          this.#is_active = state;
          keyframes.push(this.#get_property_values(content));
          state ? this.#scroll_to() : false;
          this.#animate(content, keyframes).onfinish = resolve;
        });

      if (state) await close_relations();

      await (state ? this.#on.beforeOpen(this) : this.#on.beforeClose(this));
      await toggle_self();
      await (state ? this.#on.afterOpen(this) : this.#on.afterClose(this));

      return resolve();
    });
  }
  //Public Methods
  toggle = async () => {
    await this.#on.beforeToggle(this, this.#is_active);
    await this.#do_toggle(!this.#is_active);
    await this.#on.afterToggle(this, this.#is_active);
    return Promise.resolve();
  };
  close = () => this.#do_toggle(false);
  open = () => this.#do_toggle(true);
  //Public Properties
  get isActive() {
    return this.#active;
  }
  set isActive(_) {
    console.warn(
      'Attempted to change the value of <Toggle Instance>.isActive. Toggle properties cannot be changed directly.'
    );
  }

  get container() {
    if (this.#container) return this.#container;
    else return undefined;
  }
  set container(_) {
    console.warn(
      'Attempted to change the value of <Toggle Instance>.container. Toggle properties cannot be changed directly.'
    );
  }

  get trigger() {
    if (this.#trigger) return this.#trigger;
    else return undefined;
  }
  set trigger(_) {
    console.warn(
      'Attempted to change the value of <Toggle Instance>.trigger. Toggle properties cannot be changed directly.'
    );
  }

  get content() {
    if (this.#content) return this.#content;
    else return undefined;
  }
  set content(_) {
    console.warn(
      'Attempted to change the value of <Toggle Instance>.content. Toggle properties cannot be changed directly.'
    );
  }
}
