import { processProps } from '~utils';

class Component {
  #module = null;

  constructor(options) {
    options.baseElement = options.baseElement || HTMLElement;
    this.#registerComponent(options);
  }

  async #importAssets(options) {
    const loadStyles = () =>
      typeof options.style === 'function'
        ? options.style().catch(() => Promise.resolve())
        : Promise.resolve();

    const loadScripts = () =>
      typeof options.script === 'function'
        ? options
          .script()
          .then(module => (this.#module = module.default))
          .catch(() => Promise.resolve())
        : Promise.resolve();

    return Promise.all([loadStyles(), loadScripts()]);
  }

  async #registerComponent(options) {
    const registrar = this;

    customElements.define(
      options.name,
      class extends options.baseElement {
        registrar = null;
        observers = null;
        props = null;

        constructor() {
          super();
          this.registrar = registrar;
        }

        async connectedCallback() {
          this.props = processProps(this);
          this.observers = makeObservers();

          await registrar.#importAssets(options);
          if (registrar.#module) registrar.#module.apply(this);
        }
      }
    );
  }
}

export { Component, Component as default };

function makeObservers() {
  class Observers {
    static observers = [];
  }
  class Resize {
    constructor(elements, callback) {
      const observer = new ResizeObserver(callback);

      elements =
        Symbol.iterator in Object(elements) ? [...elements] : [elements];
      elements.forEach(e => observer.observe(e));

      Observers.observers.push(observer);
      return observer;
    }
  }

  class Intersection {
    constructor(elements, callback, options) {
      const observer = new IntersectionObserver(callback, options || {});

      elements =
        Symbol.iterator in Object(elements) ? [...elements] : [elements];
      elements.forEach(e => observer.observe(e));

      Observers.observers.push(observer);
      return observer;
    }
  }

  class Mutation {
    constructor(elements, callback, config = {}) {
      const observer = new MutationObserver(callback);
      config = { childList: false, attributes: true, ...config };
      if (!Object.entries(config).some(([_, value]) => value)) return false;

      elements =
        Symbol.iterator in Object(elements) ? [...elements] : [elements];
      elements.forEach(e => observer.observe(e, config));

      Observers.observers.push(observer);
      return observer;
    }
  }

  return {
    get records() {
      return Observers.observers;
    },
    Resize,
    Intersection,
    Mutation,
  };
}
