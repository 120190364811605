import { addEvents, setCookie } from '~utils';

export default function () {
  const id = `alert--${this.props.id}--dismissed`;
  const dismiss = this.querySelector('.alert__close');
  const drawer = document.querySelector('.header .header__drawer');

  // Record that the alert was closed using a cookie name
  // that has been registered on the server.
  // Keep it closed for `dismissAlertExpirationDays` days,
  // and store the alert hash in the cookie, so that the
  // server can tell if the alert has been changed since
  // it was dismissed.
  const storeDismissed = () => {
    let cookieName = this.props.dismissCookie;
    let cookieExpirationDays = this.props.dismissCookieExpirationDays ?? 30;
    let alertHash = this.props.alertHash;
    setCookie(cookieName, alertHash, cookieExpirationDays);
  }  
  addEvents(dismiss, 'click.stop.prevent enter.stop.prevent', () =>
    doDismiss.call(this)
  );

  function doDismiss() {
    storeDismissed();
    this.classList.add('hidden');
  }
}
